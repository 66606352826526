import { graphql } from "gatsby"
import { BoundedBox } from "../components/BoundedBox"
import { MapDataToPropsCtx } from "../components/MapSlicesToComponents"
import { PrismicPageDataBodyTwoColumnText } from "../graphql.gen"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import { isFilled } from "@prismicio/helpers"
import { PrismicRichText } from "@prismicio/react"
import { Text } from "../components/Text"
import { RichTextField } from "@prismicio/types"

interface ColumnTextProps {
	richTextField: RichTextField
	children?: React.ReactNode
	className?: string
}

const ColumnText = ({
	richTextField,
	children,
	className,
}: ColumnTextProps) => {
	return (
		<div className={className}>
			<PrismicRichText
				field={richTextField}
				components={{
					heading2: (props) => (
						<Text
							variant="heading2"
							asChild
							uppercase
							className="mb-8 md:mb-10 last:mb-0 leading-1_4"
						>
							<h2>{props.children}</h2>
						</Text>
					),
					heading6: (props) => (
						<Text
							asChild
							variant="heading6"
							className="font-extrabold mt-10 md:mt-12 first:mt-0 mb-3 md:mb-5 last:mb-0"
						>
							<h3>{props.children}</h3>
						</Text>
					),
					paragraph: (props) => (
						<Text
							asChild
							variant="paragraph1"
							fontFamily="serif"
							className="mb-7 md:mb-9 last:mb-0 leading-1_4"
						>
							<p>{props.children}</p>
						</Text>
					),
				}}
			/>
			{children}
		</div>
	)
}

type Props = ReturnType<typeof mapDataToProps>

export const PageDataBodyTwoColumnText = ({
	heading,
	introCopy,
	leftColumnText,
	rightColumnText,
	image,
	imageAlt,
}: Props) => {
	return (
		<BoundedBox.Outer paddingY="twoColumnText" className="bg-white">
			<BoundedBox.Inner>
				<div className="max-w-[53.125rem] flex flex-col gap-12">
					{heading && (
						<Text variant="heading2" asChild uppercase className="leading-1_4">
							<h2>{heading}</h2>
						</Text>
					)}

					{isFilled.richText(introCopy) && (
						<div>
							<PrismicRichText
								field={introCopy}
								components={{
									paragraph: (props) => (
										<Text
											asChild
											variant="paragraph1"
											fontFamily="serif"
											className="leading-1_4"
										>
											<p>{props.children}</p>
										</Text>
									),
								}}
							/>
						</div>
					)}
				</div>
				<div className="grid md:grid-cols-2 gap-20 xl:gap-36 mt-20">
					{isFilled.richText(leftColumnText) && (
						<ColumnText
							richTextField={leftColumnText}
							className="max-w-[32.5rem]"
						/>
					)}
					{isFilled.richText(rightColumnText) && (
						<ColumnText
							richTextField={rightColumnText}
							className="max-w-[39.375rem]"
						>
							<div className="relative mt-16">
								<div>
									<GatsbyImage
										image={image}
										alt={imageAlt ?? ""}
										className="w-full h-full"
										objectFit="cover"
										objectPosition="center"
									/>
								</div>

								<svg
									viewBox="0 0 263 275"
									fill="none"
									className="w-[40%] absolute -bottom-[18%] right-[12%]"
								>
									<path
										d="M111.528 0H263L151.472 275H0L111.528 0Z"
										fill="#EC1E27"
									/>
								</svg>
							</div>
						</ColumnText>
					)}
				</div>
			</BoundedBox.Inner>
		</BoundedBox.Outer>
	)
}

export function mapDataToProps({
	data,
}: MapDataToPropsCtx<PrismicPageDataBodyTwoColumnText>) {
	const primary = data.primary

	return {
		heading: primary.heading?.text,
		introCopy: primary.intro_copy?.richText,
		leftColumnText: primary.left_column_text?.richText,
		rightColumnText: primary.right_column_text?.richText,
		image: primary.image?.gatsbyImageData as IGatsbyImageData,
		imageAlt: primary.image?.alt,
	}
}

export const fragment = graphql`
	fragment PageDataBodyTwoColumnText on PrismicPageDataBodyTwoColumnText {
		primary {
			heading {
				text
			}
			intro_copy {
				richText
			}
			left_column_text {
				richText
			}
			right_column_text {
				richText
			}
			image {
				gatsbyImageData(width: 630, sizes: "50vw", layout: FULL_WIDTH)
				alt
			}
		}
	}
`

export default PageDataBodyTwoColumnText
